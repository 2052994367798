import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/BlogPost/BlogPost.tsx";
import Audio from './Audio';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Audio mdxType="Audio" />
    <p><a parentName="p" {...{
        "href": "https://twitter.com/alexpage_"
      }}>{`Alex Page`}</a>{` and I join `}<a parentName="p" {...{
        "href": "https://twitter.com/jerodsanto"
      }}>{`Jerod`}</a>{` & `}<a parentName="p" {...{
        "href": "https://twitter.com/shortdiv"
      }}>{`Divya`}</a>{` on the JS Party Podcast. We discuss our open research, finding and selecting a viable alternative for Sass at Shopify.`}</p>
    <p><a parentName="p" {...{
        "href": "https://changelog.com/jsparty/190"
      }}>{`JS Party 190: Replacing Sass at Shopify`}</a>{` – Listen on `}<a parentName="p" {...{
        "href": "https://changelog.com/"
      }}>{`Changelog.com`}</a></p>
    <h2>{`Notes & Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Shopify/polaris/discussions/44"
        }}>{`GitHub Issue on replacing Sass`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.google.com/spreadsheets/d/1rxrRTlbNWiLVu-Q5IK7xh5O1FmWcjyAS2XN7jiPrhYM/edit?usp=sharing"
        }}>{`Polaris Solutions Matrix`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tailwindcss.com/"
        }}>{`Tailwind`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/css-modules/css-modules"
        }}>{`CSS Modules`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://stitches.dev/"
        }}>{`Stitches`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://vanilla-extract.style/"
        }}>{`vanilla-extract`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maecapozzi.com/newsletter/"
        }}>{`Design Systems Newsletter`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      